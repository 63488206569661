import React, { useCallback, useState } from 'react';
import IconButton from './IconButton';
import Section from './Section';
import MarkdownEditor from './MarkdownEditor';
import renderSanitizedMarkdown from '../lib/markdown';

function MarkdownSection(props) {
  const { title, markdown, saveEditedMarkdown } = props;
  const [editing, setEditing] = useState(false);
  const [editedMarkdown, setEditedMarkdown] = useState(markdown);

  const startEdits = useCallback(() => {
    setEditing(true);
    setEditedMarkdown(markdown);
  }, [markdown]);

  const endEdits = useCallback(() => {
    setEditing(false);
  }, []);

  const saveEdits = useCallback(() => {
    saveEditedMarkdown(editedMarkdown);
    endEdits();
  }, [editedMarkdown, saveEditedMarkdown, endEdits]);

  const buttons = editing
    ? (
      <>
        <IconButton tip="Save" icon="fa-solid fa-save" variant="outline-success" onClick={saveEdits} />
        <IconButton tip="Cancel" icon="fa-solid fa-xmark" variant="outline-dark" onClick={endEdits} />
      </>
    )
    : <IconButton tip="Edit" icon="fa-solid fa-gear" variant="outline-dark" onClick={startEdits} />;

  return (
    <Section title={title} buttons={buttons}>
      {editing ? (
        <MarkdownEditor markdown={editedMarkdown} setMarkdown={setEditedMarkdown} />
      ) : (
        /* dangerouslySetInnerHTML is used with sanitization */
        /* eslint-disable react/no-danger */
        <div className="px-3 py-2 markdown" dangerouslySetInnerHTML={{ __html: renderSanitizedMarkdown(markdown) }} />
      )}
    </Section>
  );
}

export default MarkdownSection;
