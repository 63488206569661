import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from '../../config';
import SignInWithGoogleButton from '../../components/SignInWithGoogleButton';

function SignInPage() {
  return (
    <div className="text-center p-5 mx-auto section" style={{ maxWidth: 400 }}>
      <h4>Sign In</h4>
      <div className="m-3">
        <GoogleOAuthProvider clientId={config.signInWithGoogle.clientId}>
          <SignInWithGoogleButton />
        </GoogleOAuthProvider>
      </div>
    </div>
  );
}

export default SignInPage;
