import React, { useCallback, useEffect } from 'react';
import userService from '../services/userService';
import MarkdownSection from './MarkdownSection';

function FinancialPlan() {
  const [planMarkdown, setPlanMarkdown] = React.useState('');

  const updatePlan = useCallback(() => {
    userService.getPlan().then((plan) => {
      setPlanMarkdown(plan['markdown']);
    });
  }, []);

  const savePlan = useCallback((updatedMarkdown) => {
    userService.updatePlan({ markdown: updatedMarkdown }).then(updatePlan);
  }, [updatePlan]);

  useEffect(() => {
    updatePlan();
  }, [updatePlan]);

  return (
    <MarkdownSection title="Financial Plan" markdown={planMarkdown} saveEditedMarkdown={savePlan} />
  );
}

export default FinancialPlan;
