import React from 'react';
import { languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-markdown';
import CodeEditor from './CodeEditor';

function MarkdownEditor(props) {
  const { markdown, setMarkdown } = props;

  return (
    <CodeEditor code={markdown} setCode={setMarkdown} language={languages.markdown} />
  );
}

export default MarkdownEditor;
