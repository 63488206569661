import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight } from 'prismjs/components/prism-core';
import 'prismjs/themes/prism.css';

function CodeEditor(props) {
  const { code, setCode, language } = props;

  return (
    <div style={{ maxHeight: 500, overflowY: 'scroll' }}>
      <Editor
        value={code}
        onValueChange={(c) => setCode(c)}
        highlight={(c) => highlight(c, language)}
        padding={12}
        style={{ fontFamily: '"Fira code", "Fira Mono", monospace' }}
      />
    </div>
  );
}

export default CodeEditor;
