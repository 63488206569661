import React, { useCallback, useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import userService from '../services/userService';
import { authTokenAvailable, removeToken } from '../lib/tokens';

function SignInButtons() {
  return (
    <div>
      <Link to="/signin">
        <Button variant="outline-light" className="me-2">Sign in</Button>
      </Link>
    </div>
  );
}

const LinkButton = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="link"
    style={{ 'color': 'inherit', padding: 0, border: 0 }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

function UserManager() {
  const [userInfo, setUserInfo] = useState();

  const updateUserInfo = useCallback(() => {
    userService.getUser().then(setUserInfo);
  }, []);

  const navigate = useNavigate();
  const logoutUser = useCallback(() => {
    removeToken();
    navigate('/', { replace: true });
  }, [navigate]);

  useEffect(() => {
    if (authTokenAvailable()) {
      updateUserInfo();
    }
  }, [updateUserInfo]);

  if (!authTokenAvailable()) {
    return <SignInButtons />;
  }

  if (!userInfo) return null;

  return (
    <div className="d-inline-block">
      <Dropdown align="end">
        <Dropdown.Toggle as={LinkButton}>
          <Image style={{ width: 36 }} src={userInfo.photo_url} roundedCircle />
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-1">
          <Dropdown.Header>{userInfo.name}</Dropdown.Header>
          <Dropdown.Item onClick={() => logoutUser()}>
            <FontAwesomeIcon icon="fa-solid fa-sign-out" fixedWidth />
            <span className="ms-1">Sign Out</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default UserManager;
