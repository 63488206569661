import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBucket,
  faSackDollar,
  faMoneyBills,
  faCoins,
  faRotateRight,
  faReceipt,
  faCheck,
  faPieChart,
  faEgg,
  faBriefcaseMedical,
  faBriefcase,
  faDollarSign,
  faGraduationCap,
  faGift,
  faHouseChimney,
  faCar,
  faFileInvoiceDollar,
  faBuildingColumns,
  faRightLeft,
  faChevronRight,
  faChevronDown,
  faInfoCircle,
  faPencil,
  faPlus,
  faXmark,
  faGear,
  faSave,
  faRotate,
  faTrash,
  faSignOut,
} from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

library.add(
  faBucket,
  faSackDollar,
  faMoneyBills,
  faCoins,
  faRotateRight,
  faReceipt,
  faCheck,
  faPieChart,
  faEgg,
  faBriefcaseMedical,
  faBriefcase,
  faDollarSign,
  faGraduationCap,
  faGift,
  faHouseChimney,
  faCar,
  faFileInvoiceDollar,
  faBuildingColumns,
  faRightLeft,
  faChevronRight,
  faChevronDown,
  faInfoCircle,
  faPencil,
  faPlus,
  faXmark,
  faGear,
  faSave,
  faRotate,
  faTrash,
  faSignOut,
  faGoogle,
);
