import React from 'react';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';

function FooterLink(props) {
  const { label, to } = props;

  return (
    <LinkContainer to={to}>
      <Button variant="link" className="px-2">{label}</Button>
    </LinkContainer>
  );
}

function Footer() {
  return (
    <div className="mt-4 d-flex justify-content-center flex-wrap">
      <FooterLink label="Terms" to="/info/terms" />
      <FooterLink label="Privacy" to="/info/privacy" />
      <FooterLink label="Cookies" to="/info/cookies" />
      <FooterLink label="Credits" to="/info/credits" />
      <FooterLink label="Contact" to="/info/contact" />
    </div>
  );
}

export default Footer;
