import React from 'react';
import Container from 'react-bootstrap/Container';
import Header from './Header';
import Footer from './Footer';

function Page(props) {
  const { children } = props;

  return (
    <>
      <Header />
      <Container id="main" fluid="lg" className="d-flex align-items-center">
        <div className="w-100">
          {children}
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default Page;
