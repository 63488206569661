import React from 'react';

function Section(props) {
  const { title, buttons, noDiv, children } = props;
  return (
    <div className="my-4">
      <div className="mb-2 d-flex justify-content-between align-items-end">
        <h5 className="m-0">{title}</h5>
        <div>{buttons}</div>
      </div>
      {noDiv ? children : <div className="section">{children}</div>}
    </div>
  );
}

export default Section;
