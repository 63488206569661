import React from 'react';
import FinancialPlan from '../components/FinancialPlan';

function HomePage() {
  return (
    <FinancialPlan />
  );
}

export default HomePage;
