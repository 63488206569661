import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Page from './components/Page';
import HomePage from './pages/Home';
import SignInPage from './pages/general/SignIn';
import './App.sass';
import './components/IconLibrary';
import PrivateRouteGuard from './components/PrivateRouteGarud';
import LoadingPage from './pages/general/Loading';
import LazyPreload from './components/LazyPreload';

const CapturePage = LazyPreload(() => import('./pages/Capture'));
const VisualizePage = LazyPreload(() => import('./pages/Visualize'));
const ReviewPage = LazyPreload(() => import('./pages/Review'));
const ProjectPage = LazyPreload(() => import('./pages/Project'));

function App() {
  useEffect(() => {
    CapturePage.preload();
    VisualizePage.preload();
    ReviewPage.preload();
    ProjectPage.preload();
  }, []);

  return (
    <BrowserRouter>
      <Page>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route element={<PrivateRouteGuard redirectPath="/signin" />}>
              <Route path="/capture" element={<CapturePage />} />
              <Route path="/visualize" element={<VisualizePage />} />
              <Route path="/review" element={<ReviewPage />} />
              <Route path="/project" element={<ProjectPage />} />
            </Route>
          </Routes>
        </Suspense>
      </Page>
    </BrowserRouter>
  );
}

export default App;
