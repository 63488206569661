import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import React from 'react';
import config from '../config';

function AppErrorBoundary(props) {
  const { children } = props;

  const honeybadger = Honeybadger.configure(config.honeybadger);

  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      {children}
    </HoneybadgerErrorBoundary>
  );
}

export function reportError(error, errorClass = 'Error') {
  Honeybadger.notify(error, errorClass);
}

export default AppErrorBoundary;
