const devConfig = {
  api: {
    url: '//localhost:5000/v1',
  },
  signInWithGoogle: {
    clientId: '402560372778-kkjsuj5muifc4c92m59efh61qif52rn4.apps.googleusercontent.com',
  },
  honeybadger: {
    apiKey: 'hbp_Lsz7ydiqV24a1WsbJ0QOlu6pbnKQLr01EoTY',
    environment: 'development',
    reportData: false,
  },
};

const stagingConfig = {
  api: {
    url: 'https://api.moneyapp.houstonfortney.com/v1',
  },
  signInWithGoogle: {
    clientId: '402560372778-8utpskqk6ongfea41ap2ec0169r2jctm.apps.googleusercontent.com',
  },
  honeybadger: {
    apiKey: 'hbp_Lsz7ydiqV24a1WsbJ0QOlu6pbnKQLr01EoTY',
    environment: 'staging',
    revision: process.env.HONEYBADGER_REVISION,
  },
};

function getConfig() {
  switch (window.location.hostname) {
    case 'moneyapp.houstonfortney.com':
      return stagingConfig;
    default:
      return devConfig;
  }
}

const config = getConfig();

export default config;
