import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Delayed from './Delayed';

function Loading() {
  return (
    // Slight delay before showing the loading spinner so that it doesn't flash when the page loads quickly
    <Delayed delay={250}>
      <div className="d-inline-block">
        <div className="m-2">Loading&hellip;</div>
        <div className="m-2"><Spinner animation="border" /></div>
      </div>
    </Delayed>
  );
}

export default Loading;
