import React from 'react';
import Loading from '../../components/Loading';

function LoadingPage() {
  return (
    <div className="my-5 text-center">
      <Loading />
    </div>
  );
}

export default LoadingPage;
