import React from 'react';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function IconButton(props) {
  const { icon, tip, ...other } = props;

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{tip}</Tooltip>}>
      <Button className="px-1 py-0 border-0" {...other}>
        <FontAwesomeIcon icon={icon} fixedWidth />
      </Button>
    </OverlayTrigger>
  );
}

export default IconButton;
