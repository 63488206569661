import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { authTokenAvailable } from '../lib/tokens';

function PrivateRoute(props) {
  const { redirectPath } = props;

  const location = useLocation();

  if (!authTokenAvailable()) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default PrivateRoute;
