import jwtDecode from 'jwt-decode';

export function storeToken(token) {
  localStorage.setItem('token', token);
}

export function removeToken() {
  localStorage.removeItem('token');
}

export function getToken() {
  return localStorage.getItem('token');
}

function isTokenExpired(token) {
  const currentSecondsSinceEpoch = Date.now() / 1000;
  const decoded = jwtDecode(token);
  return decoded.exp < currentSecondsSinceEpoch;
}

export function authTokenAvailable() {
  const token = getToken();
  return token !== null && !isTokenExpired(token);
}
