import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import UserManager from './UserManager';

function HeaderLink(props) {
  const { label, to } = props;

  return (
    <LinkContainer to={to}>
      <Nav.Link>{label}</Nav.Link>
    </LinkContainer>
  );
}

function Header() {
  const location = useLocation();

  return (
    <Navbar expand="md" bg="dark" variant="dark" className="px-4" sticky="top">
      <div className="order-1 w-50">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <LinkContainer to="/">
          <Navbar.Brand>
            MoneyApp
          </Navbar.Brand>
        </LinkContainer>
      </div>
      <Navbar.Collapse id="basic-navbar-nav" className="order-3 order-md-2">
        <Nav activeKey={location.pathname}>
          <HeaderLink label="Capture" to="/capture" />
          <HeaderLink label="Visualize" to="/visualize" />
          <HeaderLink label="Review" to="/review" />
          <HeaderLink label="Project" to="/project" />
        </Nav>
      </Navbar.Collapse>
      <div className="order-2 order-md-3 w-50 text-end">
        <UserManager />
      </div>
    </Navbar>
  );
}

export default Header;
