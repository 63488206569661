import ky from 'ky';
import config from '../config';
import { getToken } from './tokens';

function addAuthHeader(request) {
  const token = getToken();
  if (token) {
    request.headers.set('Authorization', token);
  }
}

const apiClient = ky.create({
  prefixUrl: config.api.url,
  headers: {
    'Content-Type': 'application/json',
  },
  hooks: {
    beforeRequest: [addAuthHeader],
  },
});

export default apiClient;
