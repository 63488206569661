import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import authService from '../services/authService';
import { storeToken } from '../lib/tokens';

function SignInWithGoogleButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const signIn = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (response) => {
      authService.getTokenFromCode(response.code).then((data) => {
        storeToken(data.token);
        navigate(from, { replace: true });
      });
    },
  });

  return (
    <Button variant="dark" onClick={() => signIn()}>
      <FontAwesomeIcon icon={['fab', 'google']} />
      {' '}
      Continue with Google
    </Button>
  );
}

export default SignInWithGoogleButton;
