import apiClient from '../lib/apiClient';

class userService {
  getUser() {
    return apiClient.get('user/').json();
  }

  getPlan() {
    return apiClient.get('user/plan').json();
  }

  updatePlan(plan) {
    return apiClient.put('user/plan', { json: plan }).json();
  }
}

export default new userService();
